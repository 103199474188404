import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppShellModule } from '@priva/appshell';
import { DynamicComponent, PrivaDynamicModule } from '@priva/components/dynamic';

import { FeatureFlagsStore } from '@app/feature-flags';
import { FeatureAccessService } from 'app/common/routing/feature-access.service';
import { CropsPageState } from 'app/pages/crops/state';

import { AppState } from './state/app.state';

@Component({
    selector: 'app-main',
    templateUrl: './app-main.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AppShellModule, PrivaDynamicModule, AsyncPipe],
})
export class AppMainComponent {
    private store = inject(Store<{ app: AppState; cropPage: CropsPageState }>);
    private featureFlagsStore = inject(FeatureFlagsStore);
    private featureAccessService = inject(FeatureAccessService);

    public activeDialogs$: Observable<DynamicComponent[]>;
    public activePanel$: Observable<DynamicComponent | undefined>;

    /* istanbul ignore next, simple select */
    constructor() {
        this.featureFlagsStore.initializeFeatureFlags([
            this.featureAccessService.showAnalysis(),
            this.featureAccessService.showManageCrop(),
        ]);

        this.activeDialogs$ = this.store.select((s) => s.app.activeDialogs);
        this.activePanel$ = this.store.select((s) => s.app.activePanel);
    }
}
